import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';

import {
  Pump,
  Pond,
  Valve,
  Pipe,
  PipePlaceholder,
  MiddlePipeL,
  WaterTank,
  AirTank,
  Compressor,
  TriplePipeFork,
  DoublePipeFork,
  PipeFork,
  ConnectionIndicator,
  ValveConnect,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowMedium,
  PumpsRowSmall,
  PumpsRowExtraSmall,
  PumpsRowCustom,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Info,
  MultiInfo,
  MultiColInfo,
  // Totals,
  Statistic,
  MobileStatTable,
  PLCStateRouter,
  PLCColorRouter,
  LoadingIndicator,
  Alarms,
} from 'isno/lib/components/displays';

import {
  InfoButton,
  VFDPumpControl,
  ToggleControl,
  AlarmReset,
  MultiSetpointControl,
} from 'isno/lib/components/controls';

import { startCase } from 'lodash';

import PLC from 'isno/lib/static/images/PLC.png';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Building Data
  buildingTemp: 'building_temperature',
  // Water Flow Source
  cityTankMode: 'city_tank_mode',
  // Water Line Data
  waterFlowRate: 'water_flow',
  suctionWaterPressure: 'suction_water_pressure',
  line3WaterFlow: 'line_3_water_flow',
  line2WaterFlow: 'line_2_water_flow',
  // Air Line Data
  line2AirFlow: 'line_2_air_flow',
  line2AirTemp: 'line_2_air_temperature',
  line3AirFlow: 'line_3_air_flow',
  line3AirTemp: 'line_3_air_temperature',
  dischargeAirPressure: 'discharge_air_pressure',
  // Pump Data
  speed: 'speed',
  dischargeWaterPressure: 'discharge_water_pressure',
  dischargeWaterTemperature: 'discharge_water_temperature',
  dischargeWaterFlow: 'discharge_water_flow',
  amps: 'current',
  hours: 'run_time',
  // Pump Alarms
  warning: 'warning',
  fault: 'fault',
  lowAmps: 'low_amps',
  masterAlarm: 'master_alarm',
  lowSuctionPressure: 'low_suction_pressure',
  emergencyStop: 'emergency_stop',
  // Valve Control
  openClose: 'open_close',
  // Valve Alarms
  // v1OpeningError: 'V1_opening_error',
  // v1ClosingError: 'V1_closing_error',
};

const componentMinWidth = '70px';

function PumpScreen(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(2),
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) { // intervalId is null if component has unmounted
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(1);
    props.fetchPLCs();
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null; // needed to prevent race condition with promise
    };
  }, []);

  const pumpMainStatusKey = {
    0: 'READY',
    1: 'RUN',
    2: 'Switch',
    3: 'High Temp',
    4: 'Low Level',
    5: 'Run Fail',
    6: 'Disabled',
    7: 'Comm',
    8: 'Local',
    9: 'Fault',
    10: 'Suction',
    11: 'LEAD',
    12: 'E-Stop',
  };

  const pumpMainStatusColorKey = {
    0: `${theme.onGreen}`,
    1: `${theme.onGreen}`,
    2: `${theme.alarm}`,
    3: `${theme.alarm}`,
    4: `${theme.alarm}`,
    5: `${theme.alarm}`,
    6: `${theme.alarm}`,
    7: `${theme.alarm}`,
    8: `${theme.alarm}`,
    9: `${theme.alarm}`,
    10: `${theme.alarm}`,
    11: `${theme.onGreen}`,
    12: `${theme.alarm}`,
  };

  const locationEquipment2 = props.locations?.locations?.[2]?.equipment;
  if (!props.locations?.locations?.[2]) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }

  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer height="fit-content" flex="3" border label={language.primary}>
            <InfoControlRow>
              <MultiSetpointControl
                title="CV1 PID"
                pGainLabel="Back P-Gain SP"
                iGainLabel="I-Gain SP"
                pGainLabel2="Hill P-Gain SP"
                modeOne={language.hill}
                modeTwo={language.back}
                settings
                pidData={locationEquipment2?.['CV1 PID']}
                IOValueIDs={[
                  `${locationEquipment2?.['CV1 PID']?.ioValues?.back_auto_setpoint?.id}`,
                  `${locationEquipment2?.['CV1 PID']?.ioValues?.hill_auto_setpoint?.id}`,
                  `${locationEquipment2?.['CV1 PID']?.ioValues?.manual_setpoint?.id}`,
                ]}
                statistics={[
                  `${locationEquipment2?.['CV1 PID']?.ioValues?.back_auto_setpoint.value}`,
                  `${locationEquipment2?.['CV1 PID']?.ioValues?.hill_auto_setpoint.value}`,
                  `${locationEquipment2?.['CV1 PID']?.ioValues?.manual_setpoint.value}`,
                ]}
                labels={[
                  `${language.autoBackSetpoint}: `,
                  `${language.autoHillSetpoint}: `,
                  `${language.manualSetpoint}: `,
                ]}
                units={[
                  locationEquipment2?.['CV1 PID']?.ioValues?.back_auto_setpoint?.ioValueType?.units,
                  locationEquipment2?.['CV1 PID']?.ioValues?.hill_auto_setpoint?.ioValueType?.units,
                  locationEquipment2?.['CV1 PID']?.ioValues?.manual_setpoint?.ioValueType?.units,
                ]}
                setIOValue={props.setIOValue}
                IOValueKeys={{
                  autoManual: 'auto_manual',
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                  pressureMode: 'hill_back',
                  pGainSetpoint: 'back_pgain_setpoint',
                  iGainSetpoint: 'igain_setpoint',
                  pGainSetpoint2: 'hill_pgain_setpoint',
                }}
                readValues={[0, 1]}
                writeValues={[0, 1]}
                buttons
              />
              <Info
                title={language.instrumentAir}
                statistic={locationEquipment2?.IA?.ioValues?.run_contact?.value ? language.enabled : language.disabled}
                color={locationEquipment2?.IA?.ioValues?.run_contact?.value ? theme.onGreen : theme.alarm}
                label=""
              />
              <Info
                title={language.airPressure}
                statistic={locationEquipment2?.IA?.ioValues?.air_pressure?.value}
                label={locationEquipment2?.IA?.ioValues?.air_pressure?.ioValueType?.units}
              />
              <Alarms
                title={language.generalAlarms}
                alarmData={locationEquipment2?.Other}
                ioValues={locationEquipment2?.Other?.ioValues}
              />
              <AlarmReset
                title={language.alarmReset}
                buttonText={language.reset}
                alarmData={locationEquipment2?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarm: 'master_alarm',
                  masterAlarmReset: 'alarm_reset',
                }}
                resetWriteValue={1}
              />
              <Info
                img={PLC}
                title={language.plcStatus}
                statistic={PLCStateRouter(props.plcs?.plcs?.[1])} // plcId 25: booster
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[1], theme)}
              />
            </InfoControlRow>
            <PumpsRowCustom height="24px" />
            <PumpsRowCustom height="60px" styles={styles.hiddenMobile}>
              <PipePlaceholder hidden />
              <PipePlaceholder hidden />
              <PipePlaceholder pipeAbove="right" />
              <PipePlaceholder horizontalAbove />
              <PipePlaceholder pipeAbove="middle" />
              <PipePlaceholder horizontalAbove>
                <Statistic
                  label={locationEquipment2?.Other?.ioValues[IOValueKeys.dischargeWaterPressure]?.ioValueType?.units}
                  border
                  statistic={locationEquipment2?.Other?.ioValues[IOValueKeys.dischargeWaterPressure]?.value}
                />
                <Statistic
                  precision={1}
                  label={locationEquipment2?.Other?.ioValues[IOValueKeys.dischargeWaterTemperature]?.ioValueType?.units}
                  border
                  statistic={locationEquipment2?.Other?.ioValues[IOValueKeys.dischargeWaterTemperature]?.value}
                />
                <Statistic
                  label={locationEquipment2?.Other?.ioValues[IOValueKeys.dischargeWaterFlow]?.ioValueType?.units}
                  border
                  statistic={locationEquipment2?.Other?.ioValues[IOValueKeys.dischargeWaterFlow]?.value}
                />
              </PipePlaceholder>
              <PipePlaceholder horizontalAbove capAfter capLabel={language.mountain} capLabelTop="20px" capLabelLeft="-60px" />
            </PumpsRowCustom>
            <PumpsRowExtraSmall styles={styles.hiddenMobile}>
              <PipePlaceholder hidden />
              <PipePlaceholder hidden />
              <ValveConnect
                connectBelow
                nameLeft="48px"
                percentTop="51px"
                percentLeft="-15px"
                percent
                valveData={{ ...locationEquipment2.CV1, name: 'CV1' }}
                readValues={[100, 0]}
                valveStateKeys={{
                  0: '0',
                  100: '100',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                }}
                IOValueKeys={{
                  position: 'position',
                }}
                greenImageKey="Opened"
                binary={false}
              />
              <PipePlaceholder hidden />
              <ValveConnect
                showOnMobile
                connectBelow
                // horizontal="above"
                nameLeft="48px"
                // nameTop="22px"
                // minWidth="9%"
                valveData={{
                  // ...locationEquipment2?.CV1,
                  // state: locationEquipment2?.CV1?.ioValues?.position?.value,
                  // id: `controlValve_${locationEquipment2?.CV1?.id}`,
                  name: 'CV2',
                }}
                setIOValue={props.setIOValue}
                valveStateKeys={{
                  0: '0',
                  100: '100',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  100: theme.onGreen,
                }}
                IOValueKeys={{
                  position: 'position',
                }}
                greenImageKey="Opened"
              />
              <PipePlaceholder hidden />
              <PipePlaceholder hidden />
            </PumpsRowExtraSmall>
            <PumpsRowExtraSmall styles={styles.hiddenMobile}>
              <PipePlaceholder hidden />
              <PipePlaceholder hidden />
              <div css={styles.flexCol}>
                <PipePlaceholder />
                <div css={css`display: flex; justify-content: center; width: 50px;`}>
                  <Statistic
                    label={locationEquipment2?.Other?.ioValues?.back_water_pressure?.ioValueType?.units}
                    border
                    statistic={locationEquipment2?.Other?.ioValues?.back_water_pressure?.value}
                  />
                </div>
                <PipePlaceholder />
              </div>
              <PipePlaceholder hidden />
              <PipePlaceholder />
              <PipePlaceholder hidden />
              <PipePlaceholder hidden />
            </PumpsRowExtraSmall>
            <PumpsRowSmall>
              <PipePlaceholder hidden />
              <Pump
                pipeAbove="right"
                controlPositionTop="-40px"
                componentMinWidth="140px"
                percent
                setIOValue={props.setIOValue}
                pumpData={locationEquipment2?.P3}
                otherData={locationEquipment2?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                }}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment2?.P3?.ioValues?.blockage,
                ]}
                statuses={[
                  {
                    id: locationEquipment2?.P3?.ioValues?.status?.id,
                    status: locationEquipment2?.P3?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.P3?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment2?.P3?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment2?.P3?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment2?.P3?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                pipeAbove="middle"
                controlPositionTop="-40px"
                componentMinWidth="140px"
                percent
                setIOValue={props.setIOValue}
                pumpData={locationEquipment2?.P2}
                otherData={locationEquipment2?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                }}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment2?.P2?.ioValues?.blockage,
                ]}
                statuses={[
                  {
                    id: locationEquipment2?.P2?.ioValues?.status?.id,
                    status: locationEquipment2?.P2?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.P2?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment2?.P2?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment2?.P2?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment2?.P2?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                pipeAbove="left"
                controlPositionTop="-40px"
                componentMinWidth="140px"
                percent
                setIOValue={props.setIOValue}
                pumpData={locationEquipment2?.P1}
                otherData={locationEquipment2?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                }}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment2?.P1?.ioValues?.blockage,
                ]}
                statuses={[
                  {
                    id: locationEquipment2?.P1?.ioValues?.status?.id,
                    status: locationEquipment2?.P1?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.P1?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment2?.P1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment2?.P1?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment2?.P1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <PipePlaceholder />
              <PipePlaceholder hidden />
              <PipePlaceholder hidden />
            </PumpsRowSmall>
            <PumpsRowExtraSmall>
              <Pond
                color={theme.pipeColors.water}
                id={`Lake_Info_${locationEquipment2?.id}`}
              >
                {/* <Info
                  title="Pond Level"
                  precision={1}
                  statistic={locationEquipment2?.Other?.ioValues?.well_level?.value}
                  label={locationEquipment2?.Other?.ioValues?.well_level?.ioValueType?.units}
                  alertText={(() => {
                    if (locationEquipment2?.Other?.ioValues?.low_water_level?.value) {
                      return 'Low Pit Level';
                    } else if (locationEquipment2?.Other?.ioValues?.low_water_level_switch?.value) {
                      return 'Low Pit Level';
                    } else {
                      return null;
                    }
                  })()}
                  alertTop="-23px"
                  alertLeft={locationEquipment2?.Other?.ioValues?.low_water_level?.value ? '-50px' : '-15px'}
                /> */}
              </Pond>
            </PumpsRowExtraSmall>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
    `,
    flexCol: css`
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 100%;
    `,
    flexRowBox: css`
      display: flex;
      flex: 1;
      height: 100%;
      min-width: 175px;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

PumpScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
};

PumpScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(PumpScreen);
