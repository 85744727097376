import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';

import {
  Pipe,
  PipePlaceholder,
  ValveConnect,
  Compressor,
  IRCompressor,
  PipeCurve,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowCustom,
  PumpsRowSmall,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Info,
  Statistic,
  PLCStateRouter,
  PLCColorRouter,
  LoadingIndicator,
  MobileStatTable,
} from 'isno/lib/components/displays';

import {
  AlarmReset,
  MultiSetpointControl,
  ToggleControl,
} from 'isno/lib/components/controls';

import PLC from 'isno/lib/static/images/PLC.png';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import AftercoolerFan from 'isno/lib/components/equipment/AftercoolerFan';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

function CompressorScreen(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(1),
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) { // intervalId is null if component has unmounted
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(1);
    props.fetchPLCs();
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null; // needed to prevent race condition with promise
    };
  }, []);

  const locationEquipment1 = props.locations?.locations?.[1]?.equipment;
  const PLC1 = props.plcs?.plcs?.[1];
  if (!props.locations?.locations?.[1] || !PLC1) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }

  const useCelsius = props.settings?.settings?.useCelsius;

  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer border backgroundColor={theme.backgroundColor} label={language.compressors}>
            <InfoControlRow>
              <MultiSetpointControl
                title="CV3 PID"
                pGainLabel="P-Gain SP"
                iGainLabel="I-Gain SP"
                settings
                pidData={locationEquipment1?.['CV3 PID']}
                IOValueIDs={[
                  `${locationEquipment1?.['CV3 PID']?.ioValues?.auto_setpoint?.id}`,
                  `${locationEquipment1?.['CV3 PID']?.ioValues?.manual_setpoint?.id}`,
                ]}
                statistics={[
                  `${locationEquipment1?.['CV3 PID']?.ioValues?.auto_setpoint?.value}`,
                  `${locationEquipment1?.['CV3 PID']?.ioValues?.manual_setpoint?.value}`,
                ]}
                labels={[
                  `${language.autoHillSetpoint}: `,
                  `${language.manualSetpoint}: `,
                ]}
                units={[
                  locationEquipment1?.['CV3 PID']?.ioValues?.auto_setpoint?.ioValueType?.units,
                  locationEquipment1?.['CV3 PID']?.ioValues?.manual_setpoint?.ioValueType?.units,
                ]}
                setIOValue={props.setIOValue}
                IOValueKeys={{
                  autoManual: 'auto_manual',
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                  pGainSetpoint: 'pgain_setpoint',
                  iGainSetpoint: 'igain_setpoint',
                }}
                readValues={[0, 1]}
                writeValues={[0, 1]}
                buttons
              />
              <MobileStatTable
                title="Air Metrics"
                stats={
                [
                  {
                    description: 'Back Air PSI',
                    stat: locationEquipment1?.Other?.ioValues?.back_air_pressure?.value,
                    label: locationEquipment1?.Other?.ioValues?.back_air_pressure?.ioValueType?.units,
                    precision: 1,
                  },
                  {
                    description: 'Discharge Air PSI',
                    stat: locationEquipment1?.Other?.ioValues.discharge_air_pressure?.value,
                    label: locationEquipment1?.Other?.ioValues.discharge_air_pressure?.ioValueType?.units,
                    alertText: locationEquipment1?.Other?.ioValues.low_air_pressure?.value ? language.lowAirPressure : null,
                  },
                  {
                    description: 'Discharge Air Temperature',
                    stat: locationEquipment1?.Other?.ioValues.discharge_air_temperature?.value,
                    label: locationEquipment1?.Other?.ioValues.discharge_air_temperature?.ioValueType?.units,
                  },
                ]
              }
              />
              <ToggleControl
                title="C1"
                ioData={locationEquipment1?.C1}
                IOValueKeys={{
                  start: 'unload_load',
                  stop: 'unload_load',
                  started: 'unload_load',
                  stopped: 'unload_load',
                }}
                setIOValue={props.setIOValue}
                button1Text="Load"
                button2Text="Unload"
                buttonWidth="55px"
                writeValues={[1, 0]}
                readValues={[1, 0]}
                dot
              />
              <ToggleControl
                title="C2"
                ioData={locationEquipment1?.C2}
                IOValueKeys={{
                  start: 'unload_load',
                  stop: 'unload_load',
                  started: 'unload_load',
                  stopped: 'unload_load',
                }}
                setIOValue={props.setIOValue}
                button1Text="Load"
                button2Text="Unload"
                buttonWidth="55px"
                writeValues={[1, 0]}
                readValues={[1, 0]}
                dot
              />
              <ToggleControl
                title="C3"
                ioData={locationEquipment1?.C3}
                IOValueKeys={{
                  start: 'unload_load',
                  stop: 'unload_load',
                  started: 'unload_load',
                  stopped: 'unload_load',
                }}
                setIOValue={props.setIOValue}
                button1Text="Load"
                button2Text="Unload"
                buttonWidth="55px"
                writeValues={[1, 0]}
                readValues={[1, 0]}
                dot
              />
              <Info
                img={PLC}
                title={language.plcStatus}
                statistic={PLCStateRouter(props.plcs?.plcs?.[1])} // plcId 25: booster
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[1], theme)}
              />
            </InfoControlRow>
            <PumpsRowCustom height="24px" />
            <PumpsRowCustom height="140px">
              <div css={[styles.curveConnectAbove, styles.aftercoolerPipePadding]}>
                <PipePlaceholder
                  minWidth="1px"
                  pipeAbove="right"
                  color={theme.pipeColors.air}
                />
              </div>
              <div css={[styles.aftercoolerPipe, styles.aftercoolerPipePadding]}>
                <PipePlaceholder
                  horizontalAbove
                  color={theme.pipeColors.air}
                  minWidth="100px"
                />
              </div>
              <div css={[styles.aftercoolerPipe, styles.aftercoolerPipePadding]}>
                <PipePlaceholder
                  horizontalAbove
                  color={theme.pipeColors.air}
                >
                  <Statistic
                    float
                    floatColor={theme.pipeColors.air}
                    label={locationEquipment1?.Other?.ioValues?.back_air_pressure?.ioValueType?.units}
                    border
                    statistic={locationEquipment1?.Other?.ioValues?.back_air_pressure?.value}
                  />
                </PipePlaceholder>
              </div>
              <div css={[styles.aftercoolerPipe, styles.aftercoolerPipePadding]}>
                <ValveConnect
                  percent
                  readOnly
                  showOnMobile
                  binary={false}
                  pipeColorAbove={theme.pipeColors.air}
                  pipeColorBelow={theme.pipeColors.air}
                  horizontal="above"
                  nameLeft="8px"
                  nameTop="-66px"
                  percentTop="24px"
                  percentLeft="2px"
                  minWidth="9%"
                  valveData={{
                    ...locationEquipment1?.CV3,
                    state: locationEquipment1?.CV3?.ioValues?.position?.value,
                    id: `controlValve_${locationEquipment1?.CV3?.id}`,
                    name: 'CV3',
                  }}
                  setIOValue={props.setIOValue}
                  readValues={[100, 0]}
                  valveStateKeys={{
                    0: '0',
                    100: '100',
                  }}
                  valveStateColorKeys={{
                    0: theme.offRed,
                    100: theme.onGreen,
                  }}
                  IOValueKeys={{
                    position: 'position',
                  }}
                  greenImageKey="Opened"
                />
              </div>
              <div css={[styles.aftercoolerPipe, styles.aftercoolerPipePadding]}>
                <PipePlaceholder
                  horizontalAbove
                  color={theme.pipeColors.air}
                >
                  <Statistic
                    label={locationEquipment1?.Other?.ioValues?.discharge_air_pressure?.ioValueType?.units}
                    border
                    statistic={locationEquipment1?.Other?.ioValues?.discharge_air_pressure?.value}
                    alertText={locationEquipment1?.Other?.ioValues.low_air_pressure?.value ? language.lowAirPressure : null}
                    alertTop="26px"
                    alertLeft="0px"
                  />
                  <Statistic
                    label={locationEquipment1?.Other?.ioValues?.discharge_air_temperature?.ioValueType?.units}
                    border
                    statistic={locationEquipment1?.Other?.ioValues?.discharge_air_temperature?.value}
                    precision={1}
                  />
                </PipePlaceholder>
              </div>
              <div css={[styles.aftercoolerPipe, styles.aftercoolerPipePadding]}>
                <PipePlaceholder
                  capLabel={language.mountain}
                  capLabelTop="20px"
                  capLabelLeft="-60px"
                  horizontalAbove
                  color={theme.pipeColors.air}
                  minWidth="100px"
                  capAfter
                />
              </div>
            </PumpsRowCustom>
            <PumpsRowCustom height="140px">
              <div css={styles.curveConnectAbove}>
                <PipeCurve color={theme.pipeColors.air} size="medium" right up />
              </div>
              <Compressor
                pipeAbove="middle"
                connectBelow={false}
                pipeColorAbove={theme.pipeColors.air}
                pipeColorBelow={theme.pipeColors.air}
                controlPositionTop="-50px"
                controlPositionLeft="80px"
                compressorData={locationEquipment1?.C1}
                setIOValue={props.setIOValue}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                }}
                readValues={[1, 0]}
                writeValues={[1, 1]}
                stats={[
                  {
                    stat: locationEquipment1?.C1?.ioValues?.current?.value,
                    label: locationEquipment1?.C1?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.C1?.ioValues?.run_time?.value,
                    label: locationEquipment1?.C1?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
                stateKey={{
                  0: language.off,
                  1: language.on,
                }}
                stateColorKey={{
                  0: theme.secondary,
                  1: theme.onGreen,
                }}
              />
              <Compressor
                pipeAbove="middle"
                connectBelow={false}
                pipeColorAbove={theme.pipeColors.air}
                pipeColorBelow={theme.pipeColors.air}
                controlPositionTop="-50px"
                controlPositionLeft="80px"
                compressorData={locationEquipment1?.C2}
                setIOValue={props.setIOValue}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                }}
                readValues={[1, 0]}
                writeValues={[1, 1]}
                stats={[
                  {
                    stat: locationEquipment1?.C2?.ioValues?.current?.value,
                    label: locationEquipment1?.C2?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.C2?.ioValues?.run_time?.value,
                    label: locationEquipment1?.C2?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
                stateKey={{
                  0: language.off,
                  1: language.on,
                }}
                stateColorKey={{
                  0: theme.secondary,
                  1: theme.onGreen,
                }}
              />
              <Compressor
                pipeAbove="middle"
                connectBelow={false}
                pipeColorAbove={theme.pipeColors.air}
                pipeColorBelow={theme.pipeColors.air}
                controlPositionTop="-50px"
                controlPositionLeft="80px"
                compressorData={locationEquipment1?.C3}
                setIOValue={props.setIOValue}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                }}
                readValues={[1, 0]}
                writeValues={[1, 1]}
                stats={[
                  {
                    stat: locationEquipment1?.C3?.ioValues?.current?.value,
                    label: locationEquipment1?.C3?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.C3?.ioValues?.run_time?.value,
                    label: locationEquipment1?.C3?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
                stateKey={{
                  0: language.off,
                  1: language.on,
                }}
                stateColorKey={{
                  0: theme.secondary,
                  1: theme.onGreen,
                }}
              />
            </PumpsRowCustom>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
    `,
    flexRowBox: css`
      display: flex;
      flex: 1;
      height: 100%;
      min-width: 175px;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
    drain: css`
      display: flex;
      height: 50px;
      flex: 1;
      width: 150px;
  `,
    drainsColumn: css`
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
      align-items: flex-start;
  `,
    valveCorner: css`
      display: flex;
      height: 100%;
      width: 75px;
  `,
    curveConnectAbove: css`
      align-items: flex-start;
      max-width: 100px;
      display: flex;
      height: 100%;
  `,
    aftercoolerWrapper: css`
      width: 83px;
      height: 100%;
    `,
    aftercoolerPipe: css`
      flex: 1;
      height: 100%;
    `,
    aftercoolerPipePadding: css`
      padding-top: 40px;
    `,
  };
};

CompressorScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
  settings: PropTypes.shape({
    settings: PropTypes.shape({
      useCelsius: PropTypes.bool,
    }),
  }).isRequired,
};

CompressorScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
  settings: state.settings,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(CompressorScreen);
